// src/pages/Home.jsx
import React, { useState,useEffect } from 'react';
import { useNavigate , Navigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { getAuth } from 'firebase/auth';
import "../css/Home.css";
import Swipeslide from './Swipeslide';

const containerStyles = {
  alignItems: "center",
  justifyContent: "center",
  width: "500px",
  margin: "0 auto",
};

const countryData = {
  India: {
    states: {
      Telangana: {
        districts: {
          Komarambheen: [
           "Wankidi","Dongargaon",
          ],
        },
      },
      Maharastra: {
        districts: {
          Chandrapur: ["Chincholi","Pudumkappi",],
        },
      },
    },
  },
};

// Popup Component
// function Popup({ onClose }) {
//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <h2 className='font-bold text-red-800'>Disclaimer</h2><br/>
//         <p className='popup-message'>The data presented
//                   on this website has been collected from various sources and
//                   may include outdated, incomplete, or inaccurate information.
//                   It is intended for general reference purposes only . <br/><br/>  
//                   Christ Focus Mission does not guarantee the accuracy or completeness
//                   of the data and is not responsible for any decisions or
//                   actions taken based on this information. Use caution and
//                   consult additional sources when making decisions.</p>
//         <button onClick={onClose} className='popup-button'>OK</button>
//       </div>
//     </div>
//   );
// }



// Terms Component
const Terms = ({ onDeclineLogout, onAccept }) => (
  <div className="terms-modal">
    <div className="terms-content">
      <h2 className="font-bold text-blue-800">
       Terms and Conditions
      </h2>
      <p className="terms-pgh">
        <b>1. Acceptance of Terms</b><br />
        By accessing or using dataforchrist.app (the "Website"), you agree to be
        bound by these Terms and Conditions. If you disagree with any part of
        the terms, you may not access the Website. <br /><br/>
        <b>2. Use of Website Data</b>
        <br/>
        2.1. The Website presents data and information related to improving the
        welfare of people, including but not limited to areas such as education,
        churches, hospitals, etc.<br/> This data is intended for:<br/><br/> <ul> <li><b> Informational
        purposes:</b></li> To provide insights and knowledge about the needs and
        challenges faced by communities.<br/><br/> <li><b> Research and analysis:</b></li> To support
        research efforts aimed at improving welfare.<br/><br/>  <li><b> Developing solutions:</b></li> To
        inspire and inform the creation of programs and initiatives that address
        social issues.</ul><br/>
       
        2.2. You may use the data on the Website for the following purposes:<br/><br/> <ul> <li><b>
        Personal education and awareness:</b></li> To learn about the needs and
        challenges faced by communities. <br/><br/> <li><b>Non-commercial research:</b></li> To conduct
        research and analysis aimed at improving welfare.<br/><br/> <li><b>Sharing with others:</b></li>
        To disseminate information and raise awareness about social issues.</ul>
        <br/>
        <b>3. Prohibited Uses of Data</b>
        <br />
        3.1. You may NOT use the data on the Website for the following purposes:<br/><br/> <ul><li><b>
        Commercial purposes:</b></li> You may not sell, license, or distribute the data
        for commercial gain.<br/><br/> <li><b> Misrepresentation:</b></li> You may not misrepresent the
        data or use it in a way that is misleading or deceptive.<br/><br/> <li><b> Discrimination
        or harm:</b></li> You may not use the data to discriminate against, harass, or
        harm any individual or group.<br/><br/> <li><b> Identifying individuals:</b></li>   You may not use
        the data to identify or track individuals without their explicit
        consent.<br/><br/><li><b> Any unlawful purpose:</b></li> You may not use the data in any way that
        violates any applicable laws or regulations.</ul>
        <br />
        <b>4. Data Attribution</b>
        <br/>
        4.1. When using data or information from the Website, you must provide
        proper attribution by:  Clearly acknowledging dataforchrist.app as the
        source. Providing a link back to the relevant page on the Website, where
        applicable.
        <br/><br/>
        <b>5. Disclaimer</b>
        <br/>
        5.1. Neither the Website (dataforchrist.app) nor its owner, Christ Focus
        Mission, makes any guarantees regarding the completeness or accuracy of
        the data presented on the Website, despite making every effort to ensure
        its reliability.<br/>5.2. The Website is provided "as is" without any
        warranties, express or implied, from either the Website itself or Christ
        Focus Mission.
        <br/><br/>
        <b>6. Limitation of Liability</b>
        <br/>
        6.1. Neither the Website (dataforchrist.app) nor its owner, Christ Focus
        Mission, will be liable for any damages arising from the use of the
        Website or any content or information on the Website.
        <br/><br/>
        <b>7. Changes to Terms and Conditions</b>
        <br/>
        7.1. The Website may update these Terms and Conditions from time to
        time. Any changes will be posted on the Website. Your continued use of
        the Website following the posting of revised Terms and Conditions
        constitutes your acceptance of the changes.
      </p>
      <div className="action-buttons">
        <button className="decline-btn" onClick={onDeclineLogout}>
          Decline and Logout
        </button>
        <button className="accept-btn" onClick={onAccept}>
          Accept
        </button>
      </div>
    </div>
  </div>
);








// Home Page
function Home() {
  const defaultCountry = "India"; // Default country
  const [countries] = useState(Object.keys(countryData));
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('selectedCountry') || defaultCountry);//defaultCountry
  const [selectedState, setSelectedState] = useState(localStorage.getItem('selectedState') || "");
  const [selectedDistrict, setSelectedDistrict] = useState(localStorage.getItem('selectedDistrict')|| "");
  const [selectedVillage, setSelectedVillageState] = useState(localStorage.getItem('selectedVillage') || ""); // Renamed to avoid conflict
  // const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [showTerms, setShowTerms] = useState(false); 
  const navigate = useNavigate();

  
// Show popup only once per login session use inside useEffect
    // const popupShown = sessionStorage.getItem('popupShown');
   // if (!popupShown) {
    //   setShowPopup(true);
    //   sessionStorage.setItem('popupShown', 'true');
    // }
    // Show terms only once per login session

  // useEffect(() => {
  //   setShowTerms(true);
  // }, []);

  // useEffect(() => {
  //   const termsShown = localStorage.getItem('termsShown');
  //   if (!termsShown) {
  //     setShowTerms(true);
  //     localStorage.setItem('termsShown', 'true');
  //     console.log("Terms shown:", termsShown);
  //   }
  // }, []);
  


  useEffect(() => {
    const termsShown = localStorage.getItem('termsShown') === 'true';
    if (!termsShown) {
      setShowTerms(true);
    }
  }, []);







// localstorage
  useEffect(() => {
    if (selectedCountry && countryData[selectedCountry]) {
      setStates(Object.keys(countryData[selectedCountry].states));
    }
    if (selectedState && countryData[selectedCountry]?.states[selectedState]) {
      setDistricts(Object.keys(countryData[selectedCountry].states[selectedState].districts));
    }
    if (selectedDistrict && countryData[selectedCountry]?.states[selectedState]?.districts[selectedDistrict]) {
      setVillages(countryData[selectedCountry].states[selectedState].districts[selectedDistrict]);
    }
  }, [selectedCountry, selectedState, selectedDistrict]);





// country
  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    localStorage.setItem('selectedCountry', country);//add


    if (countryData[country]) {
      setStates(Object.keys(countryData[country].states));
    } else {
      setStates([]);
    }
    setDistricts([]);
    setVillages([]);
    setSelectedState('');
    setSelectedDistrict('');
    setSelectedVillageState(''); // Update state
  };
// state
  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    localStorage.setItem('selectedState',state)

    if (countryData[selectedCountry]?.states[state]) {
      setDistricts(Object.keys(countryData[selectedCountry].states[state].districts));
    } else {
      setDistricts([]);
    }
    setVillages([]);
    setSelectedDistrict('');
    setSelectedVillageState(''); // Update state
  };




  // district
  const handleDistrictChange = (e) => {
    const district = e.target.value;
    setSelectedDistrict(district);
    localStorage.setItem('selectedDistrict',district);

    if (countryData[selectedCountry]?.states[selectedState]?.districts[district]) {
      setVillages(countryData[selectedCountry].states[selectedState].districts[district]);
    } else {
      setVillages([]);
    }
    setSelectedVillageState(''); // Update state

  };

// village

  const handleVillageChange = (e) => {
    const village = e.target.value;
    setSelectedVillageState(village); // Update state
    localStorage.setItem('selectedVillage', village);
    // dispatch(setSelectedVillage(village)); // Dispatch Redux action
    navigate(``);
  };



  //page clear
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    return <Navigate to="/" />;
  }

// Close popup 
// const closePopup = () => {
//   setShowPopup(false);
// };
//terms and condition decline
const handleDeclineLogout = () => {
  const auth = getAuth();
  auth.signOut().then(() => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/"); // Redirect to login page after logout
  });
};
const handleAccept = () => {
  setShowTerms(false ); // Hide terms modal
  localStorage.setItem('termsShown', 'true') 
};


  return (
    <>
     {/* {showPopup && <Popup onClose={closePopup}/>}  */}
      {showTerms &&
        <Terms
          onDeclineLogout={handleDeclineLogout}
          onAccept={handleAccept}
        />}
      
      <div className="flex blureffect">
        <Sidebar
          selectedCountry={selectedCountry} //add
          selectedVillage={selectedVillage}
          selectedState={selectedState}
          selectedDistrict={selectedDistrict}
        />
        <div className="flex-1 ">
          <div className="h-12 rounded-md shadow-md px-4 flex justify-left items-left">
            <div>
              <h1 className="text-lg font-semibold text-[#5e5c8c] mt-2">
                Home
              </h1>
            </div>
            <div
              class="bg-[url('./image/cfm6.svg')] top-0 right-1  h-0 w-0 bg-no-repeat justify-center absolute"
              style={{ height: "40px", width: "40px", marginTop: "6px" }}
            ></div>
          </div>
          <div className="image bg-full bg-no-repeat justify-items-center bg-fixed bg-cover">
            <div className="glass">


               {/*imageslide */}
               <div style={containerStyles}>
                <Swipeslide  />
              </div>
              
              <div className="flex justify-center items-center">
                <h3
                  className="text-xl block items-center font-extrabold"
                  style={{
                    marginBottom: "1vw",
                    marginTop: "1vw",
                    color: "#405cb8",
                  }}
                >
                  {" "}
              
                  Data For Christ
                  <sup
                    className=""
                    style={{
                      fontSize: "8px",
                      verticalAlign: "super",
                      paddingLeft: "1px",
                    }}
                  >
                    TM
                  </sup>
                </h3>
              </div>
              
              <div className="flex justify-center  items-center">
                <div
                  className=" w-96 p-5 pb-5 px-6 py-3 shadow-2xl bg-white bg-opacity-60 rounded-md"
                  style={{ marginTop: "0.5vw" }}
                >
                  <div className="">
                    <h4
                      className="text-xl block text-center text-gray-500 "
                      // style={{ color: "grey" }}
                    >
                      Fetch Data
                    </h4>
                    <div>
                      <form className="mt-3 space-y-6">
                        <div>
                          <label
                            htmlFor="country"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            Country
                          </label>
                          <select
                            id="country"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            className={`w-40 pl-2 px-4 py-1 text-base ml-3 border border-gray-300 text-gray-500 font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedCountry
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            style={{
                              color: selectedCountry ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select Country</option>
                            {countries.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='mt-0.5'>
                          <label
                            htmlFor="state"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            State
                          </label>
                          <select
                            id="state"
                            value={selectedState}
                            onChange={handleStateChange}
                            className={`w-40 pl-2 px-4 py-1 ml-7 text-base border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedState
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedCountry}
                            style={{
                              color: selectedState ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select State</option>
                            {states.map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="district"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            District
                          </label>
                          <select
                            id="district"
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                            className={`w-40 pl-2 px-4 py-1 ml-3 text-base border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedDistrict
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedState}
                            style={{
                              color: selectedDistrict ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select District</option>
                            {districts.map((district) => (
                              <option key={district} value={district}>
                                {district}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="village"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            Village
                          </label>
                          <select
                            id="village"
                            value={selectedVillage}
                            onChange={handleVillageChange}
                            className={`w-40 pl-2 px-4 py-1 text-base ml-3 border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedVillage
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedDistrict}
                            style={{
                              color: selectedVillage ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select Village</option>
                            {villages.map((village) => (
                              <option key={village} value={village}>
                                {village}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default Home;
