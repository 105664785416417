import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../css/Swipe.css';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import imageslide from "../swipeimage/swipe1.jpg";
import image1 from "../swipeimage/swipe2.jpg";
import image2 from "../swipeimage/swipe3.jpg";
import image3 from "../swipeimage/swipe4.jpg";
import image4 from "../swipeimage/swipe5.jpg";
import image5 from "../swipeimage/swipe6.jpg";
import image6 from "../swipeimage/swipe7.jpg";
import image7 from "../swipeimage/swipe8.jpg";
import image8 from "../swipeimage/swipe9.jpg";


export default function App() {
  return (
    <div>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000, //3sec
          disableOnInteraction: false, //keep autoplay after user interaction
        }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={imageslide} 
          alt="Nature 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image1}
            alt="Nature 2"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image2}
            alt="Nature 3"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image3}
            alt="Nature 4"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image4}
            alt="Nature 5"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image5}
            alt="Nature 6"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image6}
            alt="Nature 7"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image7}
            alt="Nature 8"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={image8}
            alt="Nature 9"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}